import React from 'react';
import classnames from 'classnames';

const Button: React.FC<React.LinkHTMLAttributes<HTMLLinkElement>> = ({
	children,
	href,
	className,
}) => {
	const classes = classnames('button', className);
	return (
		<a href={href} className={classes}>
			{children}
		</a>
	);
};

export default Button;
