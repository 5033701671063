import React from 'react';

const BlogPostList: React.FC<React.HTMLAttributes<HTMLElement>> = ({ children }) => {
	return (
		<section className="blog-posts">
			<h3 className="blog-title">Blog highlights</h3>
			<div className="wrapper">
				<div className="blog-post-layout">{children}</div>
			</div>
		</section>
	);
};

export default BlogPostList;
