import React from 'react';
import BreakLinesTexts from '@virgilsecurity/virgil-ui/src/components/Text/BreakLinesTexts';

export interface BlogPostProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
	title: string;
	meta: string;
	img: string;
	href: string;
}

const BlogPost: React.FC<BlogPostProps> = ({ title, meta, img, href, ...props }) => {
	return (
		<a
			href={href}
			{...props}
			title={title}
			style={{ backgroundImage: `url(${img})` }}
			target="_blank"
			className="blog-post-link"
		>
			<div className="blog-post-content">
				<span className="blog-post-meta">{meta}</span>
				<h3 className="blog-post-title">
					<BreakLinesTexts>{title}</BreakLinesTexts>
				</h3>
			</div>
		</a>
	);
};

export default BlogPost;
