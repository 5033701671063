import React from 'react';

export interface CaseItemProps {
	title: string;
	description: string;
	icon: string;
}

const CaseItem: React.FC<CaseItemProps> = ({ title, description, icon }) => {
	return (
		<div className="case">
			<img alt={title} src={icon} className="case-icon" />
			<div className="text-container">
				<h5 className="case-title">{title}</h5>
				<div className="case-line" />
				<p className="case-description">{description}</p>
			</div>
		</div>
	);
};

export default CaseItem;
