import React from 'react';

const Cases: React.FC<React.HTMLAttributes<HTMLElement>> = ({ children }) => {
	return (
		<section className="cases">
			<div className="wrapper">
				<h3 className="cases-title">Use cases across all industries</h3>
				<div className="cases-list">{children}</div>
			</div>
		</section>
	);
};

export default Cases;
